import React from 'react';


/**
 *
 *
 * @return {*}
 */
function RetentionAgent() {
  return (
    <>
      <h1>Retention Agent</h1>
    </>
  );
}

export default RetentionAgent;
